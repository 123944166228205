<template>
  <b-card class="text-center">
    <h3 class="mb-4">{{ $t('email_verify') }}</h3>
    <div class="links">
      <b-button @click="checkUrl">{{ $t('btn_verificated') }}</b-button>
      <br>
      <router-link :to="{ name: 'login' }">{{ $t('login') }}</router-link>
    </div>
  </b-card>
</template>
<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import Swal from 'sweetalert2'
export default {
    name: 'verify',
    layout: 'messages',
    metaInfo () {
        return { title: this.$t('verify') }
    },
    mounted () {
      if (!this.$route.query.token) {
        this.$router.push({ name: '404' })
      }
    },
    methods: {
        async checkUrl () {
          const token = this.$route.query.token
          if (typeof token !== 'undefined') {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            Cookies.set('token', token, { expires: 365 })
            await axios.post(apiUrl + 'email/verify', { token: token }).then(response => {
              if (response.data.status === 'success') {
                Swal.fire({
                      icon: 'info',
                      title: 'Інфо',
                      text: response.data.msg,
                      reverseButtons: true,
                      confirmButtonText: this.$t('ok'),
                      cancelButtonText: this.$t('cancel')
                })
                setTimeout(() => {
                  this.$router.push({ name: 'dashboard' })
                }, 1000)
              }
            }).catch(error => {
              Swal.fire({
                      icon: 'error',
                      title: 'Помилка',
                      text: error.response.data.msg,
                      reverseButtons: true,
                      confirmButtonText: this.$t('ok'),
                      cancelButtonText: this.$t('cancel')
              })
              this.$store.dispatch('auth/destroyUserLogin')
            })
          }
        }
    }
}
</script>
